@media print {
  html {
    margin: 0;
    padding: 0;
  }
  body {
    margin: 0;
    padding: 0;
  }

  @page {
    margin: 0;
    size: landscape;
  }

  .hidden-print {
    display: none !important;
  }
}
